<template>
  <div>
    <header-desktop></header-desktop>
    <main class="layoutMain main">
      <slot />
    </main>
    <Footer></Footer>
    <NavbarMobile class="d-block d-lg-none"></NavbarMobile>
  </div>
</template>
<script>
import HeaderDesktop from '../components/HeaderDesktop';
import Footer from '../components/Footer';
import NavbarMobile from '../components/NavbarMobile';
export default {
  name: `MainApp`,
  components: {
    HeaderDesktop,
    Footer,
    NavbarMobile
  }
};
</script>
<style lang="scss">
// @import "../assets/sass/main";
</style>
