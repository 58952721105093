<template>
  <MainApp>
    <UIBanner
      v-if="!isOnlyMobile"
      :keyData="'w_home'"
      :classImage="'img-683-170 img-wrapper'"
      :classLoading="'placeholders-banner-product'"
      :isHome="true"
    />
    <UIBanner
      v-if="isOnlyMobile"
      :keyData="'m_home'"
      :classImage="'img-683-170 bannerHome d-block d-lg-none'"
      classLoading="placeholders-banner-product"
      :isHome="true"
    />
    <list-product-index></list-product-index>
  </MainApp>
</template>
<script>
import UIBanner from '@/components/UI/Banner/UIBanner';
import ListProductIndex from '../components/ListProductsIndex';
import MainApp from '../layouts/MainApp';
import AccountService from '../api/account';
export default {
  components: {
    UIBanner,
    ListProductIndex,
    MainApp
  },
  beforeRouteEnter: async (to, from, next) => {
    const inviteCode = to.params.kol_code;
    if (inviteCode) {
      const validateData = await AccountService.inviteCodeValidate(inviteCode);
      if (validateData.status) {
        next((vm) => {
          if (!vm.$store.getters.getterLoggedIn) {
            vm.$store.commit('setInviteCode', inviteCode);
            vm.$store.commit('setModalRegister', true);
            if (validateData.data) {
              vm.$store.commit('setInviteCodeDisplayData', {
                ...validateData.data
              });
            }
          } else {
            vm.$router.push('/');
          }
        });
      } else {
        next('/khong-tim-thay/404');
      }
    } else {
      next();
    }
  },
  computed: {
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    }
  }
};
</script>
<style lang="sass" scoped></style>
